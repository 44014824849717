import { CountryLocale } from "Types";
import { LandingPage, StringOrNull, Order, WebPaymentResponse } from "./";

export type LandingPageDTO = {
    id: string;
    landingPageType: LandingPageType;
    groupLandingPage: GroupLandingPage | null;
    groupShops: any;
    groupCompanyOnlineLandingPages: GroupLandingCompany[];
};

export type GroupLandingPage = {
    id: string | null;
    name: string;
    facebook: string;
    instagram: string;
    tiktok?: string;
    logoUrl: string;
    publicShopIds: string[];
    sortedPublicShopIds: { id: string; sortNumber: number }[];
    sortedCompanyLandingPageIds: { id: string; sortNumber: number }[];
    landingPage: LandingPage;
};

export type GroupLandingCompany = {
    publicId: string;
    settings?: {
        companyOnlineLandingPage?: {
            backgroundImageUrl: string;
            subdomainName: string;
            breadText: string;
            headerText: string;
            groupLandingPageCardName: string;
            groupLandingPageCardDescription: string;
        };
    };
    sortNumber?: number;
    countryLocale?: CountryLocale;
};

export enum LandingPageType {
    COMPANY = "COMPANY",
    GROUP = "GROUP"
}
export enum EatingOption {
    CATERING = "CATERING",
    EAT_HERE = "EAT_HERE",
    HOME_DELIVERY = "HOME_DELIVERY",
    TAKE_AWAY = "TAKE_AWAY"
}

export enum EatingPreference {
    TAKE_AWAY = "TAKE_AWAY",
    EAT_HERE = "EAT_HERE",
    HOME_DELIVERY = "HOME_DELIVERY",
    CATERING = "CATERING",
    CATERING_TAKE_AWAY = "CATERING_TAKE_AWAY",
    CATERING_DELIVERY = "CATERING_DELIVERY"
}

export enum TypeOfServiceDisruption {
    CARD = "CARD",
    SWISH = "SWISH",
    KLARNA = "KLARNA"
}

export type OnlineEatingPreference = Exclude<EatingPreference, EatingPreference.CATERING> | "";

export type LatLngInput = Partial<{
    lat: string;
    lng: string;
}>;

export type PaymentMethod = "CARD" | "CASH" | "INVOICE" | "POSTPONED" | "SWISH";
export enum OnlinePaymentMethod {
    CARD = "CARD",
    SWISH = "SWISH",
    INVOICE = "INVOICE",
    NO_CHARGE = "NO_CHARGE",
    KLARNA = "KLARNA"
}

export enum LockableParts {
    POS_MANUAL_CARD = "POS_MANUAL_CARD",
    POS_REFUND = "POS_REFUND",
    CUSTOMER_FACING = "CUSTOMER_FACING",
    EXPRESS_SECRET_MODAL = "EXPRESS_SECRET_MODAL"
}

export type WebPaymentInformation = {
    paymentMethod: PaymentMethod;
    swishPhoneNumber?: StringOrNull;
    klarnaPaymentSessionData?: {
        sessionId: string;
        clientToken: string;
        klarnaCreateOrderResponse?: {
            type: string;
            orderId: string;
            redirectUrl: string;
            fraudStatus: string;
            authorizedPaymentMethod: {
                numberOfDays?: number;
                numberOfInstallments?: number;
                type: string;
            }[];
        };
    };
};

export type ContactInformation = {
    name: string;
    lastName?: StringOrNull;
    email?: StringOrNull;
    phoneNumber?: StringOrNull;
    phoneNumber2?: StringOrNull;
    addressLine?: StringOrNull;
    postCode?: StringOrNull;
    city?: StringOrNull;
    country?: StringOrNull;
    floorNumber?: StringOrNull;
    latLng?: LatLngInput | null;
    doorCode?: StringOrNull;
};

export type OrderInvoiceData = {
    contactName: string;
    invoiceAddress: ContactInformation;
    organisationNumber: string;
    invoiceReference?: string;
    customerNumber?: string;
    isHandled?: boolean;
};

export type UserInformation = {
    deviceInformation: any;
    onlineContactInformation: ContactInformation;
    paymentInformation: WebPaymentInformation;
};

export type ContactInformationInput = Partial<{
    country: string;
    name: string;
    personalNumber: string;
    phoneNumber: string;
    email: string;
    floorNumber: string;
    postCode: string;
    addressLine: string;
    doorCode: string;
    addressLine2: string;
    lastName: string;
    latLng: LatLngInput;
    other: string;
    city: string;
}>;

export type DiscountMetaInput = Partial<{
    ids: string[];
    amount: number;
    originalPrice: number;
    rate: number;
    totalDiscountValue: number;
    code: string;
    name: string;
}>;

export type GiftCardMeta = {
    userGiftCardId: string;
    amount: number;
};

export type SubscriptionMeta = {
    userSubscriptionId: string;
    subscriptionId: string;
    name: string;
    vatRate?: number;
};

export type ClientInformation = {
    frontendVersion: string;
    startTime: number;
    firstAddedOrder: number;
    checkoutTime: number;
    purchaseTime: number;
};

export enum Status {
    CANCELLED = "CANCELLED",
    DEBITED = "DEBITED",
    DECLINED = "DECLINED",
    ERROR = "ERROR",
    PAID = "PAID",
    VALIDATED = "VALIDATED"
}

export namespace Mutation {
    export type AddWebOrder = {
        addWebOrder: {
            order: Order;
            webPaymentResponse: WebPaymentResponse;
        };
    };
}
