import { FixedDiscount, MenuCategoryProducts } from "Types";

/**
 * [FUNCTION] check that fixed discount is active on whole menu
 * @param menuId
 * @param activeFixedDiscount
 * @returns
 */
export const hasFullMenuFixedDiscount = (menuId: string, activeFixedDiscount?: FixedDiscount) => {
    return activeFixedDiscount?.menuCategoryAndProductIds.some((menu: MenuCategoryProducts) => {
        if (menu.menuId === menuId) {
            return menu.productIds.length === 0 && menu.categoryIds.length === 0;
        }
        return false;
    });
};

/**
 * [FUNCTION] check that product / category id has fixed discount
 * @param menuId
 * @param menuCategoryId
 * @param productId
 * @param activeFixedDiscount
 * @returns
 */
export const hasProductOrCategoryFixedDiscount = (
    menuId: string,
    menuCategoryId: string,
    productId: string,
    activeFixedDiscount?: FixedDiscount
) => {
    return (
        activeFixedDiscount?.menuCategoryAndProductIds.some((menu: MenuCategoryProducts) => {
            if (menu.menuId === menuId) {
                return menu.categoryIds.includes(menuCategoryId) || menu.productIds.includes(productId);
            }
            return false;
        }) ?? false
    );
};