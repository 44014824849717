import { PosTypes } from "Constants";

export type MenuCategoryProducts = {
    menuId: string;
    categoryIds: string[];
    productIds: string[];
};

export enum APPLIED_DISCOUNT_TYPE {
    PER_PRODUCT = "PER_PRODUCT",
    TOTAL = "TOTAL"
}

export enum GENERAL_DISCOUNT_VALIDATION_STATUS {
    VALID_DISCOUNT = "VALID_DISCOUNT",
    NO_DISCOUNT_CODE = "NO_DISCOUNT_CODE",
    MAX_USAGE_REACHED = "MAX_USAGE_REACHED",
    EMAIL_ALREADY_USED = "EMAIL_ALREADY_USED",
    EMAIL_NOT_ALLOWED = "EMAIL_NOT_ALLOWED",
    EMAIL_ONLY_DISCOUNT = "EMAIL_ONLY_DISCOUNT",
    DISCOUNT_EXPIRED = "DISCOUNT_EXPIRED",
    NOT_AVAILABLE_FOR_THIS_SHOP = "NOT_AVAILABLE_FOR_THIS_SHOP",
    NOT_AVAILABLE_ON_EATING_OPTION = "NOT_AVAILABLE_ON_EATING_OPTION",
    NO_DISCOUNT_FOUND = "NO_DISCOUNT_FOUND",
    DISCOUNT_NOT_STARTED = "DISCOUNT_NOT_STARTED"
}

export enum CAMPAIGN_DISCOUNT_VALIDATION_STATUS {
    CAMPAIGN_VALID_DISCOUNT = "CAMPAIGN_VALID_DISCOUNT",
    CAMPAIGN_NOT_FOUND = "CAMPAIGN_NOT_FOUND",
    CAMPAIGN_EXPIRED = "CAMPAIGN_EXPIRED",
    CAMPAIGN_NOT_STARTED = "CAMPAIGN_NOT_STARTED",
    CAMPAIGN_MAX_USAGE_REACHED = "CAMPAIGN_MAX_USAGE_REACHED",
    CAMPAIGN_NOT_AVAILABLE_FOR_THIS_SHOP = "CAMPAIGN_NOT_AVAILABLE_FOR_THIS_SHOP",
    CAMPAIGN_NOT_AVAILABLE_ON_EATING_OPTION = "CAMPAIGN_NOT_AVAILABLE_ON_EATING_OPTION",
    CAMPAIGN_EMAIL_NOT_FOUND = "CAMPAIGN_EMAIL_NOT_FOUND"
}

/** Frontend discount validations */
export enum CHECKOUT_DISCOUNT_VALIDATIONS {
    NO_DISCOUNT_ON_SOME_PRODUCTS = "NO_DISCOUNT_ON_SOME_PRODUCTS",
    NO_DISCOUNT_ON_ALCOHOL = "NO_DISCOUNT_ON_ALCOHOL",
    NO_DISCOUNT_ON_PRODUCTS = "NO_DISCOUNT_ON_PRODUCTS",
}

export type DISCOUNT_VALIDATION_STATUS =
    | GENERAL_DISCOUNT_VALIDATION_STATUS
    | CAMPAIGN_DISCOUNT_VALIDATION_STATUS
    | CHECKOUT_DISCOUNT_VALIDATIONS;

export type DiscountValidationResponse = {
    discount: Discount | null;
    validationStatus: DISCOUNT_VALIDATION_STATUS;
};

export type Discount = {
    id: string;
    amount: number;
    rate: number;
    code: string;
    name: string;
    startDate?: string;
    endDate?: string;
    onlineCustomerMessage: string;
    companyId?: string;
    menuCategoryAndProductIds?: MenuCategoryProducts[];
    shopIds: any;
    maxRedeemed?: number;
    noRedeemed?: number;
    oneEmailLimit?: boolean;
    fixedDiscount?: boolean;
    canApplyOnEachProduct?: boolean;
    canApplyOnWholeOrder?: boolean;
    preEnabled?: boolean;
    openPriceRate?: boolean;
    combineWithOtherDiscounts?: boolean;
    eatingOptions: any;
    disabled: boolean;
    appliedDiscountType?: APPLIED_DISCOUNT_TYPE;
    orgId?: string;
    emailOnlyDiscount?: boolean;
    allowedEmails?: string[];
    availableOn?: PosTypes[];
    campaignId?: string;
    __typename?: "Discount";
};
