import { CountryLocale } from "./countryTypes";
import { UserRoles, ThirdPartyDeliveryType } from "Constants";
import { EatingPreference } from "Providers";
import { ContactInformation } from "./";
import { Discount } from "./discountTypes";
import { CartIdAndQuantity } from "./posProviderTypes";
import { CartProduct } from "./productTypes";
import { Table } from "./tableTypes";
import { ThirdPartyAccountingType } from "./accountAuthenticationTypes";
import { LockableParts } from "./graphqlTypes";

type StringOrNull = string | null;

/** need to add in type of pos admin */
export type POS = {
    id: string;
    allowPhonePostponeOrders: boolean;
    onlineExpress: boolean;
    cashChangeInputs: string[] | [];
    connectedTerminalIds: [] | string[];
    cookieId: null | string;
    description: string;
    expressPOS: {
        startScreenHeader: string;
        startScreenBread: string;
        startScreenBackgroundImgUrl: string;
        startScreenBackgroundImgBlurred: boolean;
        startScreenLogoImgUrl: string;
        allowTableService: boolean;
        tableServiceEatingOptions: string[];
        allowLimitedPOSFeatures: boolean;
    };

    menuIds: [] | string[];
    name: string;
    posControlUnitValues: { productionNumber: string };
    postponePaymentEnabled: boolean;
    puckEnabled: boolean;
    receiptPrinter: { ip: string; deviceName: string; usbLegacyPrinter: boolean };
    shopId: string;
    publicId: string;
    expressPOSVersion: string;
};

export enum AvailablePosTypes {
    POS = "POS",
    ONLINE = "ONLINE",
    EXPRESS_ONLINE = "EXPRESS_ONLINE",
    EXPRESS = "EXPRESS",
    QR_TABLE = "QR_TABLE"
}

export type CheckBoxGroupOption = {
    name: string;
    id: string;
    key: string;
};

export type KitchenPrinter = {
    name: string;
    shopId: string;
    id: string;
    noCopies: number;
    refProductCategoryIds: string[];
    thirdPartyDeliveryTypes: string[];
    eatingOptions: string[];
    splitOrderProduct: boolean;
    hideContactInfo: boolean;
    printerModel?: string;
    tableToPrint?: string[];
    filterBundleItems?: boolean;
};
export type PublicShopInfo = {
    id: string;
    name: string;
    publicId: string;
    companyId?: string;
};

export type SelectedValues = {
    selectedShop: Shop | null;
    selectedCompany: SelectedCompany;
    authenticatedUser: AuthenticatedUser;
    logout: () => void;
};

export type SelectedTerminal = {
    id: string;
    shopId: string;
    terminalId: string;
    cloudEnabled: boolean;
    description: string;
    name: string;
    __typename: string;
};

export enum PreferredPuckTab {
    NUM_PAD = "NUM_PAD",
    CUSTOM_INPUT = "CUSTOM_INPUT"
}

/** need to add in type of pos admin - and use that instead of POS (While doing pos admin too many places the type is set)*/
export type SelectedPos = {
    id: string;
    publicId: string;
    allowPhonePostponeOrders: boolean;
    connectedTerminalIds: string[];
    // This is drivethrough -- Not a general "postpone" order
    postponePaymentEnabled: boolean;
    cookieId: string;
    description: string;
    expressPOS: any;
    onlineExpress: boolean;
    name: string;
    menuIds: string[];
    puckEnabled: boolean;
    wasteOrderEnabled: boolean;
    autoRotateMenus: boolean;
    preferTakeAway: boolean;
    preferDigitalReceipts: boolean;
    shopId: string;
    preferredPuckTab: PreferredPuckTab;
    sameCardRefund: boolean;
    receiptPrinter: {
        deviceName: string;
        ip: string;
        usbLegacyPrinter: boolean;
    };
    posControlUnitValues: {
        productionNumber: string;
    };
    cashChangeInputs: {
        amount: number;
        dateTime: string;
    }[];
    expressPOSVersion: string;
};

export type AuthenticatedUser = {
    companyId: string;
    roles: UserRoles[] | [];
    shopIds: string[];
    token: StringOrNull;
    userId: StringOrNull;
    username: StringOrNull;
    fullName: StringOrNull;
    umbrellaCompanyIds: string[];
};

export type VatAmount = {
    vatRate: number;
    amount: number;
};

export type SelectedCompany = {
    id: string;
    organisationNumber: StringOrNull;
    contactInformation: ContactInformation | null;
    countryLocale: CountryLocale;
    email: StringOrNull;
    imageUrl: StringOrNull;
    name: StringOrNull;
    productCategoryIds: string[] | null;
    timezone: StringOrNull;
    updateChangeNumber: number;
    standardProductVatRate: number;
    vatRates: number[] | null;
    settings: CompanySettings;
    umbrellaCompany: boolean;
    registeredBy?: string;
};

export type CompanySettings = {
    posEnabled: boolean;
    onlineEnabled: boolean;
    stockTrackingEnabled: boolean;
    sellingAlcohol: boolean;
    thirdPartyAccountingAccessType?: ThirdPartyAccountingType | null;
    companyOnlineLandingPage: LandingPage | null;
    companyOnlineSettings: CompanyOnlineSettings | null;
    companySubscriptionSettings: CompanySubscriptionSettings | null;
    realTimeDataConnection?: RealTimeDataConnection;
};

export type RealTimeDataConnection = {
    enableRealTimeDataConnection?: boolean;
    endpointUrl?: string;
    apiKey?: string;
};

export type SelectedShop = {
    id: string;
    shop: Shop | null;
};

export type ThirdPartyDeliveryService = {
    thirdPartyDeliveryType: ThirdPartyDeliveryType;
    externalVendorId: string;
    externalChainCode: string;
    externalShopId: string;
    enableService: boolean;
    orderKey: string;
    basicAuthUsername: string;
    basicAuthPassword: string;
    basicAuth: string;
    secondaryIntegration?: boolean;
};

export type BillingInfo = {
    billingAddressLine: String;
    billingPhoneNo: String;
    billingPostCode: String;
    billingCity: String;
    organisationNumber: String;
    organisationName: String;
    billingEmail: String;
    invoiceEmail?: String;
    paymentAccount: PaymentAccount;
};

export type PaymentAccount = {
    paymentAccountType: string;
    accountNumber: string;
};

export type ShopTags = {
    generated: string[];
    always: string[];
    never: string[];
};

export type Shop = {
    activeHours: ActiveHour[];
    companyId: string;
    contactInformation: ContactInformation;
    desc: string;
    id: string;
    imageUrl: string;
    bannerUrl: string;
    menuIds: string[];
    name: string;
    organisationNumber: string;
    publicId: string;
    timezone: string;
    billingInfo: BillingInfo;
    settings: Settings;
    predefinedComments: string[];
    refBundleIdsToExcludeInKDS: string[];
    refProductCategoryIdsToExcludeInKDS: string[];
    showOnKDSWithinMinutesBeforePickup: number;
    updateChangeNumber: number;
    sortNumber?: number;
    thirdPartyDeliveryServices: ThirdPartyDeliveryService[];
    shopKPIs?: ShopKPIs;
    umbrellaShop: boolean;
    serviceFee?: ServiceFee;
    qoplaCommentLogs?: QoplaCommentLog[];
    contactPerson: ContactInformation;
};

export type QoplaCommentLog = {
    username: string;
    addedAt: Date;
    qoplaComment: string;
};

export type ThirdPartyAccountingSettings = {
    accountAuthenticationId?: string;
    thirdPartyAccountingType?: ThirdPartyAccountingType;
    accessToken?: string;
    refreshToken?: string;
    project?: string;
    costCenter?: string;
    voucherSeries?: string;
};

export type ServiceFee = {
    amount: number;
    vatRate: number;
};

export type ShopKPIs = {
    overallRatings?: OverallRatings;
};

export type OverallRatings = {
    noOfFiveStars: number;
    noOfFourStars: number;
    noOfThreeStars: number;
    noOfTwoStars: number;
    noOfOneStars: number;
    noOfReviews: number;
    score?: number;
};

export type LandingPage = {
    subdomainName: string;
    headerText: string;
    breadText: string;
    backgroundImageUrl: string;
    headerTextColor: string;
    breadTextColor: string;
    expressOnline: boolean;
};

export type StaffManagementSystem = {
    systemName?: string;
    computerName?: string;
    guid?: string;
    apiKey?: string;
    managementType: StaffManagementType;
    lastExportDate?: string | moment.Moment;
    isValid?: boolean;
};

export enum StaffManagementType {
    "CASPECO" = "CASPECO",
    "PERSONALKOLLEN" = "PERSONALKOLLEN"
}

export type CompanyOnlineSettings = {
    facebook: string;
    instagram: string;
};

export type CompanySubscriptionSettings = {
    friendlySubdomainName: string;
    title: string;
    description: string;
    logo: string;
    imageUrl: string;
    vatRate: number;
    minimumPurchaseInterval: number;
    billingInfo?: {
        billingEmail: string;
        paymentAccount: {
            paymentAccountType: string;
            accountNumber: string;
        };
    };
};

export type Company = {
    id: string;
    name: string;
    imageUrl: string;
    companyOnlineLandingPage: LandingPage;
    contactInformation?: ContactInformation;
    companyOnlineSettings: {
        facebook: string;
        instagram: string;
    };
    shops: Shop[];
    countryLocale: CountryLocale;
};

export type Settings = {
    enableKitchenPrinterOnline: boolean;
    enableKitchenPrinterPOS: boolean;
    allowedNumberOfOrdersInKdsBatch: number;
    allowedPaymentMethods: string[];
    dailyRoutineTime: string;
    enableDoneCookingStatus: boolean;
    enableDonePreppingStatus: boolean;
    enablePickupTabInOrderDisplay: boolean;
    enableKitchenDisplay: boolean;
    enableSIEFileGeneration: boolean;
    enablePunchClock: boolean;
    enablePickUpScreenQrOnReceipt: boolean;
    refProductCategoryIdsToExcludeInKDS: string[];
    refBundleIdsToExcludeInKDS: string[];
    kitchenPrinters: KitchenPrinter[];
    onlineSettings: OnlineSettings;
    homeDeliverySettings: HomeDeliverySettings;
    shopTables: Table[];
    cateringSettings: CateringSettings;
    showOnKDSWithinMinutesBeforePickup: number;
    updateChangeNumber: number;
    allowCustomerFacingPOS: boolean;
    unlockCodeCustomerFacingPOS?: string;
    predefinedComments?: string[];
    topicId?: string;
    enableStaffManagementService?: boolean;
    staffManagementSystem?: StaffManagementSystem;
    protectSecretModalWithAdminCode?: boolean;
    separateKitchenOrderProducts?: boolean;
    thirdPartyAccountingSettings?: ThirdPartyAccountingSettings;
    lockableParts?: LockableParts[];
    lockableAccessCode?: string;
    partnerPromotionId?: string;
    allowCommentsInExpressPOS: boolean;
    tags: ShopTags;
    enableAutoAcceptThirdPartyOrders: boolean;
    predefinedThirdPartyCompletionTime: number;
    pickupSettings: { hiddenProducts: string[] };
    expressPOSSettings: ExpressPOSSettingsType;
};

export type ExpressPOSSettingsType = {
    expressPrimary: string;
    expressSecondary: string;
    advertisingImageUrl: string;
};

export type CustomCateringDate = {
    days: string[];
    message?: string;
};

export type CateringLastOrderDay = {
    dayOfWeek: "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY";
    date: string;
    stoppingHour: string;
};

export type CateringSettings = {
    enableCatering: boolean;
    emailToConfirm: string;
    noticeHours: number;
    timeToConfirm: number | null;
    deliveryAreas: DeliveryArea[];
    acceptInvoicePayment: boolean;
    minOrderDeliveryAmount: number;
    minOrderPickupAmount: number;
    customCateringDates: CustomCateringDate[];
    customSpecificCateringDates: CustomCateringDate[];
    noPickupHours: boolean;
    lastOrderDay: CateringLastOrderDay | null;
    deliveryMessageToCustomer: StringOrNull;
    pickupMessageToCustomer: StringOrNull;
};

export type QrQuickServe = {
    qrPickupOptionTableId: string;
    qrQuickServeMessage: string;
    enableExtraFieldOnlyForQuickServe: boolean;
};

export type OnlineSettings = {
    alwaysAllowPostponePickupTime: boolean;
    customClosedMessage: string | null;
    enableOnlineOrders: boolean;
    pauseOnlineOrders: boolean;
    rushHour: boolean;
    rushHourWaitMinutes: number;
    onlineMenuIds: string[];
    expressOnlineMenuIds: string[];
    expressOnlineOrderConfirmation: boolean;
    onlineOrderPickupTimes: number[];
    closeOnlineOrders: boolean;
    instagram: string | null;
    facebook: string | null;
    restaurantOffline: boolean;
    eatingOptions: EatingPreference[];
    qrEatingOptions: EatingPreference[];
    onlyAsap: boolean;
    onlineOrderPickupTimesPostpone: number[];
    commentToClient: string;
    disableShopComments: boolean;
    commentPlaceholder: string;
    alcoholicBeverages: boolean;
    sendSmsNoticeForPickup: boolean;
    productCommentsEnabled: boolean;
    restaurantOnlineOrderUrl?: string;
    enableExpressOnline: boolean;
    useHomePage: boolean;
    url: string;
    autoAcceptOnlineBasicOrders: boolean;
    predefinedCompletionTime: number | null;
    qrOnlineHideTips: boolean;
    defaultTip: number;
    onlineControlUnitPublicPosId: StringOrNull;
    mandatoryCommentPlaceholder?: StringOrNull;
    enableMandatoryComment: boolean;
    qrOnlineAutoOrderConfirmation: boolean;
    expressOnlineTextColor: string;
    qrQuickServe: QrQuickServe;
    disableTableService?: boolean;
};

export type DeliveryAreaPostalCodeRange = {
    rangeStart: number;
    rangeEnd: number;
    excluded: number[];
};

export type DeliveryArea = {
    name: string;
    deliveryFee: number;
    enabled: boolean;
    postalCodeRanges: DeliveryAreaPostalCodeRange[];
    postalCodes: number[];
    isQoplaArea?: boolean;
    customDates?: CustomCateringDate[];
    allowPickup?: boolean;
    pickupAddress?: string;
};

export type HomeDeliverySettings = {
    enableHomeDelivery: boolean;
    pauseHomeDelivery: boolean;
    minAmountFreeDelivery: number;
    minOrderTotalAmount: number;
    postalCodePlaceholder: string | null;
    deliveryAreas: DeliveryArea[];
    thirdPartyDelivery: {
        thirdPartyDeliveryType: ThirdPartyDeliveryType;
        apiKey: string;
    };
};

export type ActiveHour = {
    dayOfWeek: string;
    startingHour: string;
    stoppingHour: string;
    closed?: boolean;
};

export type GlobalQoplaSettings = {
    allShopsClosed: {
        enabled: boolean;
    };
    systemMessage: {
        enabled: boolean;
        messageId: string;
    };
};

export type QoplaMessage = {
    id?: string;
    title?: string;
    text?: string;
    type?: "GLOBAL" | "SHOP";
    level?: "NONE" | "INFO" | "SUCCESS" | "WARNING" | "ERROR";
    shopIds?: string[];
    email?: boolean;
    orderDisplay?: boolean;
};

export type SplitCartProductsState = {
    toPay: CartProduct[];
    available: CartProduct[];
};

export type SplitOrderWindowDiscounts = {
    discount?: Discount;
    discountedIdsAndQuantity?: CartIdAndQuantity[];
    discountedProductIds: string[];
};

export type SplitOrderState = {
    ids: string[];
    orderNo: number | null;
};

export type SplitState = {
    mode: "split" | "regular";
    fromOrderWindowId: null | string; // referenceOrderWindowId
    to: string[]; // targetOrderWindowIds
};

export type ProductsStockValue = { remainingQuantity: number; initialQuantity: number };
export type ProductsStock = Map<string, ProductsStockValue>;
